import React, { Component } from 'react'
import { PopoverBody, Popover } from 'reactstrap'
import classNames from 'classnames'
import Popper from '@popperjs/core'
import { POSITIONS, SIZES } from '@elo-kit/constants/general.constants'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import './tooltip-more.scss'

interface Props {
  id?: number | string
  placement?: Popper.Placement
  onOpen?: () => void
  onClose?: () => void
  size?: string
  smallHeight?: boolean
  extraSmallHeight?: boolean
  popoverClassName?: string
  iconClassName?: string
  customIcon?: React.ReactNode | ((props: any) => React.ReactNode)
  children?: React.ReactNode
  closeTooltip?: boolean
}

interface State {
  tooltipOpened: boolean
}

const defaultProps: Partial<Props> = {
  placement: POSITIONS.top,
  size: SIZES.medium,
  smallHeight: false,
  extraSmallHeight: false,
  iconClassName: 'tooltip-more__icon popover-info-icon',
}

export class EloTooltipMore extends Component<Props, State> {
  static displayName = 'EloTooltipMore'
  static defaultProps = defaultProps

  state: State = {
    tooltipOpened: false,
  }

  componentDidMount() {
    window.addEventListener('blur', this.hideTooltip)
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.closeTooltip !== this.props.closeTooltip && this.props.closeTooltip) {
      this.hideTooltip()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.hideTooltip)
  }

  hideTooltip = () => {
    this.setState({ tooltipOpened: false })
    this.props.onClose?.()
  }

  showTooltip = () => {
    this.setState({ tooltipOpened: true })
    this.props.onOpen?.()
  }

  render() {
    const {
      id,
      placement,
      size,
      children,
      customIcon,
      iconClassName,
      smallHeight,
      extraSmallHeight,
      popoverClassName,
    } = this.props

    const { tooltipOpened } = this.state

    const tooltipId = `EloToolTip${id}`

    const iconProps = {
      onMouseEnter: this.showTooltip,
      onMouseLeave: this.hideTooltip,
      id: tooltipId,
      className: classNames('tooltip-more__info-icon', iconClassName),
    }

    const popoverClasses = classNames(
      `tooltip-more tooltip-more--${size}`,
      {
        'tooltip-more--small-height': smallHeight,
        'tooltip-more--extra-small-height': extraSmallHeight,
      },
      popoverClassName
    )

    let iconContent
    const popoverContent = (
      <Popover
        id={tooltipId}
        placement={placement}
        isOpen={tooltipOpened}
        innerClassName={popoverClasses}
        target={tooltipId}
        className={popoverClasses}
      >
        <PopoverBody>{children}</PopoverBody>
      </Popover>
    )

    if (typeof customIcon === 'function') {
      iconContent = (
        <>
          {customIcon(iconProps)}
          {popoverContent}
        </>
      )
    } else {
      iconContent = (
        <div {...iconProps}>
          <EloInfoIcon />
          {popoverContent}
        </div>
      )
    }

    return iconContent
  }
}
