import {
  PRICING_INTERVALS,
  getPricingIntervalsLabels,
} from 'shared/components/pricing-plans/pricing-plan-form/PricingPlan.constants'

export const MIN_TERM_PERIOD = 1

export const SPLITTED_PAYMENT_PLANS = {
  limitedSubscription: 'limited_subscription',
  installment: 'installment',
}
export const SPLITTED_PAYMENT_PLANS_LIST = Object.values(SPLITTED_PAYMENT_PLANS)

export const PAYMENT_PLANS = {
  oneTime: 'one_time',
  subscription: 'subscription',
  ...SPLITTED_PAYMENT_PLANS,
}
export const PAYMENT_PLANS_LIST = Object.values(PAYMENT_PLANS)

export const PAYMENT_PLAN_ONE_TIME = ['one_time']

export const PRICING_PLAN_INTERVAL_OPTIONS = PRICING_INTERVALS.map((interval) => ({
  value: interval,
  label: getPricingIntervalsLabels()[interval],
}))

export const CUSTOM_DATES = [1, 7, 14, 21, 28]

export const CUSTOM_DATES_OPTIONS = CUSTOM_DATES.map((day) => ({
  value: day,
  label: I18n.t('react.cabinet.pricing_plan.form.custom_start_day', { day }),
}))

export const getPricingPlanFormLocale = () => ({
  one_time: I18n.t('react.common.one_time'),
  subscription: I18n.t('react.common.subscription'),
  limited_subscription: I18n.t('react.common.limited_subscription'),
  installment: I18n.t('react.common.installment'),
})

export const BULK_PRICING_PLANS_METHODS = {
  add: 'add',
  remove: 'remove',
}

export const BULK_PRICING_PLANS_STEPS = {
  methodSelection: 1,
  paymentSelection: 2,
  pricingPlansUpdate: 3,
  successPage: 4,
}

export const MIN_INSTALLMENT_PAYMENT_COUNT = 2
export const MAX_INSTALLMENT_PAYMENT_COUNT = 24
export const MAX_INSTALLMENT_PAYMENT_COUNT_FOR_NS = 100
