export type ExperimentName = (typeof allExperimentNames)[number]

export type GateName = ''
export type DynamicConfigName = 'user_browser' | 'user_country'

export type ExperimentUser = {
  userID: string
  custom: {
    userSessionId: string
    userId: string
    sellerId: string
  }
}

export const allExperimentNames = [
  'a-a',
  'show_new_pricing_plan_50-50',
  'show_updated_payment_methods_order',
  'show_new_payment_methods_ui',
  'a-a-single',
  'a-a-multiple',
  'show_combined_pricing_plans_new_summary_payment_ui',
  'a-a-multiple-2',
] as const

export const activeExperimentNames: Extract<(typeof allExperimentNames)[number], string>[] = [
  'show_combined_pricing_plans_new_summary_payment_ui',
  'show_updated_payment_methods_order',
  'a-a-multiple-2',
]
