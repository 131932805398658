import { COLORS } from '@elo-kit/constants/general.constants'
import { FOOTER } from '@elo-kit/constants/contentPage.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlue, eloOrange, eloWhite } = COLORS

// TODO: update with using createUseStyles
const footerStyles = (theme) => ({
  footerContainer: {
    border: theme.previewMode ? 'dashed 1px #00000021' : 'none',
    background: theme.backgroundColor || eloWhite,
    padding: '20px',
  },
  hideFooter: {
    display: 'none',
  },
  footerContainerYellowBorder: {
    border: 'dashed 1px #00000021',
    position: 'relative',
    '&:hover': {
      borderColor: eloOrange,
      '& .yellow-message': {
        visibility: 'visible',
      },
    },
  },
  yellowMessage: {
    color: eloWhite,
    height: 25,
    padding: '0 15px',
    display: 'flex',
    zIndex: 1,
    position: 'absolute',
    right: 0,
    fontSize: 11,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: eloOrange,
    borderBottomLeftRadius: 4,
    visibility: 'hidden',
  },
  poweredBy: {
    right: 15,
    bottom: -1,
    background: ['rgba(255,255,255,0.85)', '!important'],
    padding: ['7px 10px', '!important'],
    borderRadius: '5px 5px 0 0',
    position: 'absolute',

    '& > a': {
      height: 20,

      '& > p': {
        color: '#656565',
        opacity: 0.5,
        fontFamily: 'Montserrat Medium',
        fontSize: 12,
        marginBottom: 0,
        marginRight: 10,
      },
    },
    '@media (max-width: 540px)': {
      padding: ['3px 10px', '!important'],
    },
  },
  poweredByImg: {
    width: '66px',
    height: '100%',
  },
  footer: {
    maxWidth: '1140px',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
  },
  footerColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    margin: '0 20px',

    '&.single': {
      margin: 0,
      flexDirection: 'row',
      flexWrap: 'wrap',

      '& > div': {
        display: 'none',
      },

      '& > a': {
        margin: '0 20px',
      },
    },
  },
  menuTitle: {
    fontSize: theme[FOOTER.menuSize] ? `${theme[FOOTER.menuSize]}px` : '15px',
    fontFamily: cleanupFonts(theme[FOOTER.menuFont]) || 'Montserrat Medium',
    ...getFontStyles(theme[FOOTER.menuStyle]),
    color: theme[FOOTER.menuColor] || '#9e9e9e',
    textTransform: 'uppercase',
    margin: '20px 0 10px',
  },
  menuItem: {
    fontSize: theme[FOOTER.submenuSize] ? `${theme[FOOTER.submenuSize]}px` : '13px',
    fontFamily: cleanupFonts(theme[FOOTER.submenuFont]) || 'Montserrat Reg',
    ...getFontStyles(theme[FOOTER.submenuStyle]),
    color: `${theme[FOOTER.submenuColor] || eloBlue} !important`,
    lineHeight: 2,
    '&:hover': {
      color: theme[FOOTER.submenuColor] || eloBlue,
      textDecoration: theme[FOOTER.submenuStyle] === 'underline' ? theme[FOOTER.submenuStyle] : 'none',
      opacity: 0.8,
    },
    '@media (max-width: 576px)': {
      fontSize: theme[FOOTER.submenuSize] > 30 ? 30 : theme[FOOTER.submenuSize],
    },
  },
  socials: {
    background: theme[FOOTER.socialsColor] || eloBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    color: eloWhite,
    margin: '0 5px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8,
      color: eloWhite,
    },
    '&:first-child': {
      marginLeft: 0,
    },
  },
  socialsPreview: {
    background: eloWhite,
    border: 'dashed 1px #00000021',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    margin: '0 5px',
  },
  socialsContainer: {
    display: 'flex',
  },
  '@media (max-width: 991px)': {
    footer: {
      flexDirection: 'column',
    },
    footerColumn: {
      '&.single': {
        '& > a': {
          margin: '0 10px',
        },
      },
    },
  },
})

export default footerStyles
