import React, { Component } from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { FOOTER, SOCIALS, SOCIAL_ICONS, SHOP_SOCIAL_ICONS } from '@elo-kit/constants/contentPage.constants'
import { CUSTOM_CLASS_NAME_OPTION, FOOTER_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'

import { createId } from '@elo-kit/utils/general.utils'
import { internalURL } from '@elo-kit/utils/contentBlock.utils'

import footerStyles from 'shared/components/content-page/preview/blocks/footer/Footer.styles'
import { SocialLink as Social } from '../../components/socials'

const SocialLink = withStyles(footerStyles)(({ ...props }) => <Social {...props} />)
/**
 * Footer Container
 */
export const FooterContainer = withStyles(footerStyles)(({ classes, children, customClassName }) => (
  <div
    className={classNames(
      FOOTER_CLASS_NAMES.containerClassName,
      customClassName,
      'position-relative',
      classes.footerContainer
    )}
    id='footer-block'
  >
    <div className={classes.footer}>{children}</div>
  </div>
))

/**
 * Footer Column
 */
export const FooterColumn = withStyles(footerStyles)((props) => {
  const { classes, children, title, socials, singleColumn, previewMode } = props

  return (
    <div
      className={classNames(FOOTER_CLASS_NAMES.footerColumnClassName, classes.footerColumn, singleColumn && 'single')}
    >
      <div
        className={classNames(FOOTER_CLASS_NAMES.footerMenuTitleClassName, classes.menuTitle)}
        {...(previewMode && {
          'data-highlighter-item': socials
            ? BLOCK_MENU_HIGHLIGHT_IDS.footer.websiteAndSocials
            : BLOCK_MENU_HIGHLIGHT_IDS.footer.menuItems,
          'data-highlighter-selector': socials ? '' : `.${FOOTER_CLASS_NAMES.footerMenuTitleClassName}`,
          'data-highlighter-parent': socials ? '' : `.${FOOTER_CLASS_NAMES.containerClassName}`,
        })}
      >
        {title}
      </div>
      {socials ? (
        <div
          className={classes.socialsContainer}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.footer.websiteAndSocials,
            'data-highlighter-selector': `.${classes.socialsContainer} > a`,
          })}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  )
})

/**
 * Footer Menu Items
 */
export const MenuItem = withStyles(footerStyles)((props) => {
  const { classes, url, name, isDefault, locales, previewMode } = props

  const getDefaultMenuItemName = () => {
    const LEGAL_SECTION_NAMES = {
      '/document/imprint': locales.imprint,
      '/document/privacy': locales.privacy,
      '/document/terms_of_business': locales.terms,
      'publisher-landing/': locales.affiliate,
    }

    return LEGAL_SECTION_NAMES[Object.keys(LEGAL_SECTION_NAMES).find((key) => url.includes(key))] || ''
  }

  const menuItemName = isDefault ? getDefaultMenuItemName() : name

  return (
    <a
      href={!previewMode ? url : undefined}
      className={classNames(FOOTER_CLASS_NAMES.footerMenuItemClassName, classes.menuItem)}
      target='_blank'
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.footer.submenuStyle,
        'data-highlighter-selector': `.${FOOTER_CLASS_NAMES.footerMenuItemClassName}`,
        'data-highlighter-parent': `.${FOOTER_CLASS_NAMES.footerColumnClassName}`,
      })}
    >
      {menuItemName}
    </a>
  )
})

/**
 * Powered By component
 */
export const PoweredBy = withStyles(footerStyles)(({ classes, primeLogo, locales }) => (
  <div className={classNames(FOOTER_CLASS_NAMES.footerPoweredByClassName, classes.poweredBy)}>
    <a className='d-flex align-items-center text-decoration-none' href='https://elopage.com'>
      <p>{locales.poweredBy}</p>

      <div className='h-100 d-flex align-items-center'>
        <img className={classes.poweredByImg} alt='logo' src={primeLogo} />
      </div>
    </a>
  </div>
))

/**
 * Footer block for page builder
 */
export class FooterBlock extends Component {
  render() {
    const {
      toJS,
      primeLogo,
      defaultMenuItems,
      block: { content = {}, id: blockId } = {},
      previewMode,
      baseURL,
      isAppActive,
      themePages,
      seller = {},
      view,
      badgeAppKey,
      locales = {
        poweredBy: this.props.I18n.t('react.shared.powered_by'),
        imprint: this.props.I18n.t('react.shared.common.imprint'),
        privacy: this.props.I18n.t('react.shared.common.privacy'),
        terms: this.props.I18n.t('react.shared.common.terms'),
        affiliate: this.props.I18n.t('react.shared.common.affiliate'),
      },
      isCheckoutPage,
    } = this.props
    const emptyPreview = content && Object.keys(toJS(content)).length < 2
    const showPoweredBy = !isAppActive(badgeAppKey)
    const menuList = content && content[FOOTER.menuList] ? content[FOOTER.menuList] : defaultMenuItems

    const socialIcons = view === ELOPAGE_CABINETS.shop ? SHOP_SOCIAL_ICONS : SOCIAL_ICONS
    const powerSellerParam = seller?.powerSeller && !isCheckoutPage ? '?power_seller=true' : ''

    return (
      <ThemeProvider
        theme={{
          ...content,
          previewMode,
        }}
      >
        <FooterContainer previewMode customClassName={content[CUSTOM_CLASS_NAME_OPTION]}>
          {menuList &&
            menuList.map((menuItem) => (
              <FooterColumn
                key={menuItem.id}
                title={menuItem.name}
                singleColumn={menuList.length === 1}
                setTitle
                blockId={blockId}
                previewMode={previewMode}
              >
                {menuItem.submenu.map((submenu) => (
                  <MenuItem
                    isDefault={submenu.default}
                    key={submenu.id}
                    url={`${internalURL(baseURL, submenu, themePages, seller?.username)}${powerSellerParam}`}
                    name={submenu?.name}
                    locales={locales}
                    previewMode={previewMode}
                    blockId={blockId}
                  />
                ))}
              </FooterColumn>
            ))}

          {content[FOOTER.socialsIsShown] && (
            <FooterColumn title={content[FOOTER.socialsTitle]} blockId={blockId} socials previewMode={previewMode}>
              {SOCIALS.map((social, i) =>
                !emptyPreview
                  ? content[social] && (
                      <SocialLink
                        social={social}
                        key={createId(`socials-${i}`)}
                        url={content[social]}
                        icon={socialIcons[social]}
                        previewMode={previewMode}
                      />
                    )
                  : i < 2 && (
                      <SocialLink
                        key={createId(`socials-${i}`)}
                        emptyPreview={emptyPreview}
                        previewMode={previewMode}
                        social={social}
                      />
                    )
              )}
            </FooterColumn>
          )}

          {showPoweredBy ? <PoweredBy primeLogo={primeLogo} locales={locales} /> : null}
        </FooterContainer>
      </ThemeProvider>
    )
  }
}

FooterBlock.propTypes = {
  /** Badge App key */
  badgeAppKey: PropTypes.string,
  /** View from contentPageStore */
  view: PropTypes.string,
  /** Seller meta from contentPageStore */
  seller: PropTypes.object,
  /** Theme Pages from contentPageStore */
  themePages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Base URL */
  baseURL: PropTypes.string,
  /** Page builder block object */
  block: PropTypes.object,
  /** Default Menu Items */
  defaultMenuItems: PropTypes.array,
  /** Preview mode flag */
  previewMode: PropTypes.bool,
  /** Map of locales */
  locales: PropTypes.object,
  /** isAppActive from contentPageStore */
  isAppActive: PropTypes.func,
  /** toJS func from mobX */
  toJS: PropTypes.func,
  /** Logo which should be shown */
  primeLogo: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.element, PropTypes.string]),
}

FooterBlock.defaultProps = {
  badgeAppKey: 'elopage-badge',
  themePages: [],
  isAppActive: () => {},
  toJS: (val) => val,
  block: {},
  previewMode: false,
  primeLogo: null,
  I18n: {
    t: () => {},
  },
}

export const Footer = (props) => {
  const I18n = useI18n()

  return <FooterBlock I18n={I18n} {...props} />
}
